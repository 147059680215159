import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { FileUpload } from "primereact/fileupload";
import { Divider } from "primereact/divider";
import { ProgressBar } from "primereact/progressbar";

import { Dialog } from "primereact/dialog";
import { useGlobalContext } from "../Config";
import "../assets/ultimate.css";

const Proveedor = (props) => {
  const { baseUrl, headerText } = useGlobalContext();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const idCotizacion = params.get("cotizacion") || "Default value for param1";

  const [loading, setLoading] = useState(false);

  const [cotizacion, setCotizacion] = useState(null);
  const [encodedFile, setEncodedFile] = useState(null);
  const [solicitud, setSolicitud] = useState(null);

  const endpointCotizacion =
    baseUrl + "getCotizacion?id_cotizacion=" + idCotizacion;
  const endpointSolicitud =
    baseUrl + "getSolicitud?id_solicitud=" + cotizacion?.solicitud;

  useEffect(() => {
    const fetchData2 = async () => {
      try {
        const requestOptions = {
          method: "GET",
          headers: headerText,
          redirect: "follow",
          credentials: "include",
        };
        const response = await fetch(endpointCotizacion, requestOptions);
        const jsonData = await response.json();
        // Set the initial value using data from the fetched JSON object

        setCotizacion(jsonData.cotizacion);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData2();
  }, [endpointCotizacion]);
  useEffect(() => {
    const fetchDataSolicitud = async () => {
      try {
        const requestOptions = {
          method: "GET",
          headers: headerText,
          redirect: "follow",
          credentials: "include",
        };
        const response = await fetch(endpointSolicitud, requestOptions);
        const jsonData = await response.json();
        setSolicitud(jsonData.solicitud);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    if (cotizacion !== null && cotizacion !== undefined) {
      fetchDataSolicitud();
    }
  }, [cotizacion]);
  const handleFileUploadProveedor = (event, rowData) => {
    const file = event.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const base64Data = btoa(e.target.result);

        // Determine content type based on file extension
        const contentType = file.type;
        const fileName = file.name;
        const fileSize = file.size;

        setEncodedFile(base64Data);

        subirArchivoProveedor(
          JSON.stringify({
            contentType: contentType,
            fileName: fileName,
            base64: base64Data,
            fileSize: fileSize,
            idSolicitud: parseInt(cotizacion.solicitud, 10),
            idProveedor: cotizacion.codigo.toString(),
            idCotizacion: cotizacion.id,
          })
        );
      };

      reader.readAsBinaryString(file); // Fallback for other types of files
    }
  };
  const subirArchivoProveedor = async (jsonData) => {
    const endpoint = baseUrl + "adjuntarArchivoCotizacion";

    try {
      const response = await fetch(endpoint, {
        method: "POST",
        body: jsonData,
        headers: headerText,
      });

      if (!response.ok) {
        throw new Error("Failed to create the request");
      }
      const data = await response.json();
      if (data.respuesta) {
        window.location.reload();
      } else {
        alert("Hubo un problema al subir el archivo");
      }
    } catch (error) {
      console.error("Error:", error.message);
      // Handle other errors (e.g., network error)
    }
  };

  return (
    <div className="grid dashboard">
      <div className="col-12 md:col-12">
        <div className="col-12">
          <div className="card">
            <div className="grid">
              <div
                className="col-12"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <img
                  src={`${baseUrl}descargar/logo/empresa?id_empresa=${solicitud?.empresaId}`}
                  alt="No pudo encontrarse el logo de la empresa"
                  style={{ maxWidth: "100%", height: "auto" }}
                />
                {cotizacion ? (
                  <>
                    {(() => {
                      if (!cotizacion.archivos) {
                        return (
                          <>
                            <h5>Bienvenido {cotizacion.nombreProveedor}</h5>
                            <p>
                              Sube aquí tu cotizacion para la solicitud #
                              {cotizacion.solicitud}
                            </p>
                            <p>1) Seleccione el archivo a subir</p>
                            <p>
                              2) Una vez el nombre del archivo aparezca en el
                              botón, presione nuevamente el botón para subir el
                              archivo
                            </p>
                            <p>3) El archivo quedará en el sistema</p>
                            <label style={{ fontWeight: "bold" }}>
                              Archivo Cotizacion:
                            </label>
                            <br />
                            <br />
                            <FileUpload
                              mode="basic"
                              chooseLabel="Subir Archivo"
                              uploadLabel="Subir Archivo"
                              cancelLabel="Cancelar"
                              multiple={false}
                              customUpload
                              uploadHandler={(e) =>
                                handleFileUploadProveedor(e)
                              }
                            />
                          </>
                        );
                      } else {
                        return (
                          <>
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <h5>Bienvenido {cotizacion.nombreProveedor}</h5>
                            <p>
                              Hemos recibido su cotizacion para la solicitud #
                              {cotizacion.solicitud}
                            </p>
                          </>
                        );
                      }
                    })()}
                  </>
                ) : (
                  <p>Cargando Solicitud ...</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        header=""
        visible={loading}
        modal
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
      >
        <div className="loading-screen">
          <ProgressBar
            mode="indeterminate"
            style={{ height: "6px" }}
          ></ProgressBar>
          <p>Procesando ...</p>
        </div>
      </Dialog>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return (
    prevProps.location.pathname === nextProps.location.pathname &&
    prevProps.colorMode === nextProps.colorMode &&
    prevProps.isNewThemeLoaded === nextProps.isNewThemeLoaded &&
    prevProps.onNewThemeChange === nextProps.onNewThemeChange
  );
};

export default React.memo(Proveedor, comparisonFn);
