import React, { useEffect, useState } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { InputTextarea } from "primereact/inputtextarea";
import { ProgressSpinner } from "primereact/progressspinner";
import { Column } from "primereact/column";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
import { FileUpload } from "primereact/fileupload";
import { Divider } from "primereact/divider";
import { ProgressBar } from "primereact/progressbar";

import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import { useGlobalContext } from "../Config";
import "../assets/ultimate.css";

import { Calendar } from "primereact/calendar";
import { format } from "date-fns";

const Proveedores = (props) => {
  const { baseUrl, headerText } = useGlobalContext();
  const [loading, setLoading] = useState(true);
  const [globalFilter, setGlobalFilter] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [proveedores, setProveedores] = useState(null);
  let empresa = JSON.parse(sessionStorage.getItem("selectedEmpresa"));
  const endpoint = baseUrl + "getProveedores?id_empresa=" + empresa.id;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetch(endpoint, {
          method: "GET",
          credentials: "include",
          redirect: "follow",
          headers: headerText,
        }).then((res) => res.json());

        // Set the initial value using data from the fetched JSON object
        setProveedores(data.proveedores);
        setFilteredData(data.proveedores);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const filters = {
    id: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  };

  const customHeaderStyle = { backgroundColor: "#e0dddc" };

  const applyGlobalFilter = (event) => {
    setGlobalFilter(event.target.value);
    setFilteredData(
      proveedores.filter((item) =>
        Object.values(item).some((value) =>
          String(value).toLowerCase().includes(event.target.value.toLowerCase())
        )
      )
    );
  };

  return (
    <div>
      <h5>Proveedores:</h5>
      <div className="formgrid grid">
        <div className="field col">
          <label style={{ fontWeight: "bold" }}>Buscar: </label>
          <InputText
            type="text"
            placeholder="Codigo o Nombre"
            onChange={applyGlobalFilter}
          ></InputText>
        </div>
        <div className="field col"></div>
      </div>
      {loading ? (
        <div style={{ textAlign: "center", marginTop: "50px" }}>
          <ProgressSpinner
            style={{ width: "50px", height: "50px" }}
            strokeWidth="4"
          />
        </div>
      ) : (
        <DataTable
          value={filteredData}
          responsiveLayout="scroll"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          paginator
          currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} proveedores"
          dataKey="id"
          emptyMessage="No se encuentran proveedores"
          className="datatable-responsive"
          rows={15}
          filters={filters}
          rowHover
          showGridlines
        >
          <Column
            field="codigo"
            header="Codigo"
            sortable
            headerStyle={customHeaderStyle}
          ></Column>
          <Column
            field="nombreProveedor"
            header="Proveedor"
            sortable
            headerStyle={customHeaderStyle}
          ></Column>
          <Column
            field="ciudad"
            header="Ciudad"
            sortable
            headerStyle={customHeaderStyle}
          ></Column>
          <Column
            field="direccion"
            header="Dirección"
            sortable
            headerStyle={customHeaderStyle}
          ></Column>
          <Column
            field="telefono"
            header="Teléfono"
            sortable
            headerStyle={customHeaderStyle}
          ></Column>
        </DataTable>
      )}
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return (
    prevProps.location.pathname === nextProps.location.pathname &&
    prevProps.colorMode === nextProps.colorMode &&
    prevProps.isNewThemeLoaded === nextProps.isNewThemeLoaded &&
    prevProps.onNewThemeChange === nextProps.onNewThemeChange
  );
};

export default React.memo(Proveedores, comparisonFn);
