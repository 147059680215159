import React, { useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { useGlobalContext } from "../Config";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { classNames } from "primereact/utils";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Chip } from "primereact/chip";

const SolicitantesTable = (props) => {
  const { baseUrl, headerText } = useGlobalContext();
  const [usuariosSolicitantes, setUsuariosSolicitantes] = useState([]);
  const [isEditDialogVisible, setIsEditDialogVisible] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedUsuario, setSelectedUsuario] = useState(null);
  const [newSolicitante, setNewSolicitante] = useState("");
  const [newDepartamento, setNewDepartamento] = useState("");
  const [clienteSeleccionado, setClienteSeleccionado] = useState();
  const [clientes, setClientes] = useState([]);
  const [empresas, setEmpresas] = useState([]);
  const [empresasSeleccionadas, setEmpresasSeleccionadas] = useState([]);
  const [id, setId] = useState(null);

  const handleCheckboxChange = (empresaId) => {
    setEmpresasSeleccionadas((prevSelected) =>
      prevSelected.includes(empresaId)
        ? prevSelected.filter((id) => id !== empresaId)
        : [...prevSelected, empresaId]
    );
  };
  const fetchData = async () => {
    const endpoint = baseUrl + "getAllUsuariosSolicitantes";

    try {
      const data = await fetch(endpoint, {
        method: "GET",
        credentials: "include",
        redirect: "follow",
        headers: headerText,
      }).then((res) => res.json());

      if (data.mensaje !== "OK") {
        throw new Error("Failed to fetch data");
      }
      // Set the initial value using data from the fetched JSON object
      setUsuariosSolicitantes(data.usuariosSolicitantes);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const insertUsuarioSolicitante = async (
    nombre,
    departamento,
    idCliente,
    idEmpresas
  ) => {
    const params = new URLSearchParams();
    params.append("nombre", nombre);
    params.append("departamento", departamento);
    params.append("idCliente", idCliente);
    params.append("idEmpresas", idEmpresas);

    // Create the full URL with encoded parameters
    const endpoint = `${baseUrl}insertUsuarioSolicitante?${params.toString()}`;

    try {
      const data = await fetch(endpoint, {
        method: "POST",
        credentials: "include",
        redirect: "follow",
        headers: headerText,
      }).then((res) => res.json());

      if (data.mensaje !== "OK") {
        throw new Error(data.mensaje || "Failed to create the request");
      }

      await fetchData();
    } catch (error) {
      alert(error);
      console.error("Error:", error.message);
    }
  };

  const updateUsuarioSolicitante = async (
    id,
    nombre,
    departamento,
    idEmpresas,
    activo
  ) => {
    const params = new URLSearchParams();
    params.append("id", id);
    params.append("nombre", nombre);
    params.append("departamento", departamento);
    params.append("idEmpresas", idEmpresas);
    params.append("activo", activo);

    // Create the full URL with encoded parameters
    const endpoint = `${baseUrl}updateUsuarioSolicitante?${params.toString()}`;

    try {
      const data = await fetch(endpoint, {
        method: "POST",
        credentials: "include",
        redirect: "follow",
        headers: headerText,
      }).then((res) => res.json());

      if (data.mensaje !== "OK") {
        throw new Error("Failed to create the request");
      }

      await fetchData();
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  const getClientes = async () => {
    const endpoint = baseUrl + "getClientes";
    try {
      const data = await fetch(endpoint, {
        method: "GET",
        credentials: "include",
        redirect: "follow",
        headers: headerText,
      }).then((res) => res.json());

      if (data.mensaje !== "OK") {
        throw new Error("Failed to fetch data");
      }

      setClientes(data.clientes);
    } catch (error) {
      console.error("Error", error.message);
    }
  };
  const getEmpresasByIdCliente = async (idCliente) => {
    const endpoint = baseUrl + `getEmpresasByIdCliente?idCliente=${idCliente}`;
    try {
      const data = await fetch(endpoint, {
        method: "GET",
        credentials: "include",
        redirect: "follow",
        headers: headerText,
      }).then((res) => res.json());

      if (data.mensaje !== "OK") {
        throw new Error("Failed to fetch data");
      }

      setEmpresas(data.empresas);
    } catch (error) {
      console.error("Error", error.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const filters = {
    nombre: { value: null, matchMode: "startsWith" },
    departamento: { value: null, matchMode: "startsWith" },
    cliente: { value: null, matchMode: "startswith" },
    estado: { value: null, matchMode: "equals" },
  };

  const customHeaderStyle = { backgroundColor: "#e0dddc" };
  const clearVariables = () => {
    props.setCurrentStep(1);
    props.setIsDialogVisible(false);
    setClienteSeleccionado([]);
    setEmpresas([]);
    setEmpresasSeleccionadas([]);
    setClientes([]);
    setNewDepartamento(null);
    setNewSolicitante(null);
    setIsEdit(false);
  };
  const handleContinue = async () => {
    const { currentStep, setCurrentStep } = props;

    if (
      newSolicitante !== undefined &&
      newSolicitante !== null &&
      newSolicitante.trim() !== "" &&
      newDepartamento !== undefined &&
      newDepartamento !== null &&
      newDepartamento.trim() !== ""
    ) {
      let nextStep;

      // Check if we're on step 1 and want to go to the next step
      if (currentStep === 1) {
        nextStep = isEdit ? 3 : 2;
      } else {
        nextStep = currentStep + 1;
      }

      if (nextStep === 2) {
        await getClientes();
      } else if (nextStep === 3) {
        if (
          clienteSeleccionado &&
          clienteSeleccionado.id &&
          Object.keys(clienteSeleccionado).length > 0
        ) {
          await getEmpresasByIdCliente(clienteSeleccionado.id);
        } else {
          alert("Seleccione un cliente antes de continuar.");
          return;
        }
      }

      setCurrentStep(nextStep);
    } else {
      alert("Complete todos los campos.");
    }
  };

  const handleBack = () => {
    props.setCurrentStep(props.currentStep - 1); // Regresa al paso anterior
  };

  const handleSubmit = async () => {
    if (empresasSeleccionadas.length === 0) {
      alert("Seleccione al menos una empresa.");
    } else {
      console.log(isEdit);
      if (isEdit) {
        await updateUsuarioSolicitante(
          id,
          newSolicitante,
          newDepartamento,
          empresasSeleccionadas,
          true
        );
      } else {
        await insertUsuarioSolicitante(
          newSolicitante,
          newDepartamento,
          clienteSeleccionado.id,
          empresasSeleccionadas
        );
      }
    }
    clearVariables();
  };

  const renderButtons = (rowData) => {
    return (
      <div style={{ display: "flex", gap: "10px" }}>
        <Button
          outlined
          icon="pi pi-user-edit"
          onClick={() => handleEdit(rowData)}
          style={{ backgroundColor: "#28a745", color: "white" }}
          className="p p-button-rounded"
        />
        {rowData.activo ? (
          <>
            <Button
              outlined
              icon="pi pi-times"
              onClick={() => handleDelete(rowData)}
              style={{ backgroundColor: "#dc3545", color: "white" }}
              className="p p-button-rounded"
              disabled={!rowData.activo}
            />
          </>
        ) : (
          <>
            <Button
              outlined
              icon="pi pi-plus"
              onClick={() => handleActivate(rowData)}
              style={{ backgroundColor: "#28a745", color: "white" }}
              className="p p-button-rounded"
              disabled={rowData.activo}
            />
          </>
        )}
      </div>
    );
  };

  const handleEdit = (rowData) => {
    setNewSolicitante(rowData.nombre);
    setNewDepartamento(rowData.departamento);
    setClienteSeleccionado(rowData.cliente);
    setId(rowData.id);
    setIsEdit(true);
    props.setIsDialogVisible(true);
  };

  const toggleEstado = (rowData) => {
    // Lógica para cambiar el estado del solicitante
  };

  const handleDelete = async (rowData) => {
    await updateUsuarioSolicitante(
      rowData.id,
      rowData.nombre,
      rowData.departamento,
      [],
      false
    );
    await fetchData();
  };

  const handleActivate = async (rowData) => {
    await updateUsuarioSolicitante(
      rowData.id,
      rowData.nombre,
      rowData.departamento,
      [],
      true
    );
    await fetchData();
  };

  const openEditDialog = (rowData) => {
    setSelectedUsuario(rowData);
    props.setIsEditDialogVisible(true);
  };

  const handleEditSave = () => {
    // Implementar la lógica para guardar los cambios en usuariosSolicitantes
    const updatedUsers = usuariosSolicitantes.map((user) =>
      user.id === selectedUsuario.id ? selectedUsuario : user
    );
    setUsuariosSolicitantes(updatedUsers);

    props.setIsDialogVisible(false);
  };

  const handleEditChange = (field, value) => {
    setSelectedUsuario({ ...selectedUsuario, [field]: value });
  };
  const renderEstado = (rowData) => {
    return rowData.activo ? (
      <Chip
        label="Activo"
        style={{ backgroundColor: "#28a745", color: "#fff" }}
      />
    ) : (
      <Chip
        label="Bloqueado"
        style={{ backgroundColor: "#dc3545", color: "#fff" }}
      />
    );
  };
  console.log(
    newSolicitante,
    newDepartamento,
    clienteSeleccionado,
    props.currentStep,
    empresasSeleccionadas,
    isEdit
  );
  return (
    <div className="">
      <DataTable
        value={usuariosSolicitantes}
        responsiveLayout="scroll"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        dataKey="id"
        paginator
        emptyMessage="No se encuentran registros asociados"
        className="datatable-responsive"
        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} solicitudes"
        rows={10}
        rowHover
        showGridlines
      >
        <Column
          field="nombre"
          sortable
          filter
          header="Nombre"
          headerStyle={customHeaderStyle}
          filterValue={filters["nombre"].value}
          onFilter={(e) => (filters["nombre"].value = e.value)}
          body={(rowData) => rowData.nombre.toUpperCase()}
        ></Column>
        <Column
          field="departamento"
          sortable
          filter
          header="Departamento"
          headerStyle={customHeaderStyle}
          filterValue={filters["departamento"].value}
          onFilter={(e) => (filters["departamento"].value = e.value)}
          body={(rowData) => rowData.departamento.toUpperCase()}
        ></Column>
        <Column
          field="cliente.nombre"
          sortable
          filter
          header="Cliente"
          headerStyle={customHeaderStyle}
          filterValue={filters["cliente"].value}
          onFilter={(e) => (filters["cliente"].value = e.value)}
          body={(rowData) => rowData.cliente.nombre.toUpperCase()}
        ></Column>

        <Column
          field="estado"
          sortable
          filter
          header="Estado"
          headerStyle={customHeaderStyle}
          body={renderEstado}
          filterValue={filters["estado"]?.value || ""}
          onFilter={(e) => (filters["estado"].value = e.value)}
        />
        <Column
          header="Acciones"
          headerStyle={customHeaderStyle}
          body={renderButtons}
        ></Column>
      </DataTable>

      <Dialog
        header="Agregar Nuevo Solicitante"
        visible={props.isDialogVisible}
        style={{ width: "75vw" }}
        onHide={clearVariables}
        footer={
          <div>
            {props.currentStep > 1 && (
              <Button
                label="Atrás"
                icon="pi pi-chevron-left"
                onClick={handleBack}
              />
            )}
            {props.currentStep < 3 && (
              <Button
                label="Continuar"
                icon="pi pi-chevron-right"
                onClick={handleContinue}
              />
            )}
            {props.currentStep === 3 && (
              <Button
                label="Finalizar"
                icon="pi pi-check"
                onClick={handleSubmit}
              />
            )}
          </div>
        }
      >
        {props.currentStep === 1 && (
          <div className="grid p-fluid">
            <div className="col-12 md:col-12">
              <div className="card">
                <h5>Ingrese datos del nuevo solicitante</h5>
                <div className="grid formgrid">
                  <div className="col-6 md:col-6">
                    <InputText
                      value={newSolicitante}
                      onChange={(e) => setNewSolicitante(e.target.value)}
                      placeholder="Nombre del Solicitante"
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div className="col-6 md:col-6">
                    <InputText
                      value={newDepartamento}
                      onChange={(e) => setNewDepartamento(e.target.value)}
                      placeholder="Departamento"
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {props.currentStep === 2 && (
          <div className="col-12 md:col-12">
            <h5>Seleccione al grupo de empresas</h5>
            <Dropdown
              value={clienteSeleccionado}
              placeholder="Selecione una opción"
              options={clientes}
              onChange={(e) => setClienteSeleccionado(e.value)}
              optionLabel="nombre"
              style={{ width: "100%" }}
            />
          </div>
        )}

        {props.currentStep === 3 && (
          <div className="col-12 md:col-12">
            <h5>Escoja las empresas del nuevo solicitante</h5>
            <div className="p-field-checkbox">
              {empresas?.map((empresa) => (
                <div key={empresa?.id} className="field-checkbox">
                  <Checkbox
                    inputId={empresa?.id}
                    name="empresas"
                    value={empresa?.id}
                    checked={empresasSeleccionadas?.includes(empresa?.id)}
                    onChange={() => handleCheckboxChange(empresa?.id)}
                  />
                  <label htmlFor={empresa?.id}>{empresa?.nombre}</label>
                </div>
              ))}
            </div>
          </div>
        )}
      </Dialog>
    </div>
  );
};

export default SolicitantesTable;
