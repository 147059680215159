import { createContext, useContext, useState } from "react";
const GlobalContext = createContext();
export const useGlobalContext = () => useContext(GlobalContext);

const AppContext = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isRecuperar, setIsRecuperar] = useState(false);
  const [usuario_id, setUsuario_id] = useState();
  const headerText = new Headers();
  headerText.append("Content-Type", "application/json;charset=UTF-8");

  // AUTORIZACIÓN BETA Y ALPHA COMENTAR PARA PROD
  // headerText.append(
  //   "Authorization",
  //   "Basic c29wb3J0ZUBrZXNwc2VydmljaW9zLmNsOmtlc3A="
  // );
  // AUTORIZACIÓN BETA Y ALPHA COMENTAR PARA PROD

  // DESCOMENTAR PARA LOCAL
  // const [baseUrl, setBaseUrl] = useState(
  //   "http://localhost:8080/adquisiciones.prime.rest/rest/"
  // );
  // DESCOMENTAR PARA LOCAL

  // DESCOMENTAR PARA BETA
  // const [baseUrl, setBaseUrl] = useState(
  //   "https://adquisiciones.kesp.cl/adquisiciones.prime.service/rest/"
  // );
  // DESCOMENTAR PARA BETA

  // DESCOMENTAR PARA PRD
  const [baseUrl, setBaseUrl] = useState(
    "https://adquisiciones.kesp.cl/adquisiciones.prime.prd.service/rest/"
  );
  // DESCOMENTAR PARA PRD

  return (
    <GlobalContext.Provider
      value={{
        baseUrl,
        setBaseUrl,
        isLoggedIn,
        setIsLoggedIn,
        usuario_id,
        setUsuario_id,
        isRecuperar,
        setIsRecuperar,
        headerText, // Now available globally
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default AppContext;
